import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import './404.scss'
import locationIcon from '../assets/images/hover-icons/location-icon.png'

const NotFoundPage = (): any => {
  const [isLocationsPage, setIsLocationsPage] = useState(true)

  useEffect(() => {
    if (window.location.pathname.includes('locations')) {
      navigate('/our-properties', {
        state: {
          searchInput: '',
          location: window.location.pathname.slice(11),
          type: '',
          price: '',
        },
      })
    } else {
      setIsLocationsPage(false)
    }
  }, [])

  return (
    <>
      {isLocationsPage ? null : (
        <div className="not-found-page">
          <img src={locationIcon} alt="not-found-icon" />
          <h1>Opps! Page not found.</h1>
          <p>Sorry, the page you are looking for does not exist.</p>
          <a href="/">Back to home</a>
        </div>
      )}
    </>
  )
}

export default NotFoundPage
